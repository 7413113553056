<template>
  <div>
    <a-card title="新增检验报告">
      <template slot="extra" style="margin: -4px 0">
        <a-button type="primary" icon="left" ghost @click="$router.go(-1)">返回</a-button>
      </template>

      <a-form-model ref="form" :model="dataForm" :rules="dataRules" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
        <a-row>
          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="number" label="检验报告单号">
              <a-input v-model="dataForm.number" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="stock_in_order" label="关联单据">
              <StockInOrderSelect v-model="dataForm.stock_in_order" type="purchase" @change="productItems = []" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="inspection_date" label="检验日期">
              <a-date-picker v-model="dataForm.inspection_date" valueFormat="YYYY-MM-DD" style="width: 100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="inspector" label="检验人">
              <a-select v-model="dataForm.inspector" style="width: 100%">
                <a-select-option v-for="item in userItems" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="inspection_basis" label="检验依据">
              <a-select v-model="dataForm.inspection_basis" mode="tags" style="width: 100%">
                <a-select-option value="图纸">图纸</a-select-option>
                <a-select-option value="样板">样板</a-select-option>
                <a-select-option value="作业指导">作业指导</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="inspection_appearance" label="检验外观">
              <a-textarea v-model="dataForm.inspection_appearance" allowClear />
            </a-form-model-item>
          </a-col>
          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="inspection_dimensions" label="检验规格">
              <a-textarea v-model="dataForm.inspection_dimensions" allowClear />
            </a-form-model-item>
          </a-col>
          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="inspection_function" label="检验功能">
              <a-textarea v-model="dataForm.inspection_function" allowClear />
            </a-form-model-item>
          </a-col>
          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="inspection_testing" label="检验测试">
              <a-textarea v-model="dataForm.inspection_testing" allowClear />
            </a-form-model-item>
          </a-col>
          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="inspection_installation" label="检验试装">
              <a-textarea v-model="dataForm.inspection_installation" allowClear />
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="processing_results" label="M.R.B处理结果">
              <a-select v-model="dataForm.processing_results" mode="tags" style="width: 100%">
                <a-select-option value="挑选使用(Sort)">挑选使用(Sort)</a-select-option>
                <a-select-option value="加工/处理(Rework)">加工/处理(Rework)</a-select-option>
                <a-select-option value="让步接受(Accept of special)">让步接受(Accept of special)</a-select-option>
                <a-select-option value="退货(Reject)">退货(Reject)</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="inspection_result" label="检验结果">
              <a-select v-model="dataForm.inspection_result" style="width: 100%">
                <a-select-option value="qualified">合格</a-select-option>
                <a-select-option value="unqualified">不合格</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="inspection_content" label="检验报告">
              <a-textarea v-model="dataForm.inspection_content" allowClear />
            </a-form-model-item>
          </a-col> -->
          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="remark" label="备注">
              <a-input v-model="dataForm.remark" allowClear />
            </a-form-model-item>
          </a-col>
          <a-col :span="6" style="width: 320px">
            <a-form-item prop="inspection_files" label="附件">
              <InspectionFileUpload v-model="dataForm.inspection_files" :initialItems="[]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form-model>

      <!-- <a-divider orientation="left">检验标准</a-divider>
      <a-table
        size="small"
        :columns="inspectionStandardsHearder"
        :dataSource="dataForm.inspection_standards"
        :pagination="false"
        style="width: 480px"
      >
        <template slot="cr" slot-scope="value, item">
          <a-input-number v-model="item.cr" size="small" />
        </template>
        <template slot="maj" slot-scope="value, item">
          <a-input-number v-model="item.maj" size="small" />
        </template>
        <template slot="min" slot-scope="value, item">
          <a-input-number v-model="item.min" size="small" />
        </template>
      </a-table> -->

      <a-divider orientation="left">质检产品</a-divider>
      <div>
        <a-button type="primary" @click="openProductSelectModal">选择产品</a-button>
      </div>

      <div style="margin-top: 12px">
        <a-table rowKey="id" size="small" :columns="tableColumns" :dataSource="productItems" :pagination="false">
          <template slot="inspection_quantity" slot-scope="value, item">
            <a-input-number v-model="item.inspection_quantity" size="small" />
          </template>
          <template slot="qualified_quantity" slot-scope="value, item">
            <a-input-number v-model="item.qualified_quantity" size="small" />
          </template>
          <template slot="unqualified_quantity" slot-scope="value, item">
            <a-input-number v-model="item.unqualified_quantity" size="small" />
          </template>
          <template slot="action" slot-scope="value, item">
            <a-button-group size="small">
              <a-button type="danger" @click="removeProduct(item)">移除</a-button>
            </a-button-group>
          </template>
        </a-table>
      </div>

      <div style="margin-top: 32px">
        <a-popconfirm title="确定创建吗?" @confirm="create">
          <a-button type="primary" :loading="createLoading">创建</a-button>
        </a-popconfirm>
      </div>
    </a-card>

    <StockInGoodsSelectModal
      v-model="productSelectModalVisible"
      :stockInOrder="dataForm.stock_in_order"
      @select="onSelectProduct"
    />
  </div>
</template>

<script>
import { inspectionReportNumber, inspectionReportCreate } from "@/api/inspection";
import { userOption } from "@/api/option";
import moment from "moment";

export default {
  components: {
    StockInOrderSelect: () => import("@/components/StockInOrderSelect"),
    StockInGoodsSelectModal: () => import("@/components/StockInGoodsSelectModal"),
    InspectionFileUpload: () => import("@/components/InspectionFileUpload"),
  },
  data() {
    return {
      dataForm: {
        inspection_standards: [
          { aql: "ACCEPT", cr: null, maj: null, min: null },
          { aql: "REJECT", cr: null, maj: null, min: null },
          { aql: "外观", cr: null, maj: null, min: null },
          { aql: "规格", cr: null, maj: null, min: null },
          { aql: "功能", cr: null, maj: null, min: null },
          { aql: "测试", cr: null, maj: null, min: null },
          { aql: "试装", cr: null, maj: null, min: null },
          { aql: "总数", cr: null, maj: null, min: null },
        ],
      },
      dataRules: {
        number: [
          { required: true, message: "请输入检验报告单号", trigger: "change" },
          { max: 32, message: "超出最大长度 (32)", trigger: "change" },
        ],
        stock_in_order: [{ required: true, message: "请选择关联单据", trigger: "change" }],
        inspection_date: [{ required: true, message: "请选择检验日期", trigger: "change" }],
        inspector: [{ required: true, message: "请选择检验人", trigger: "change" }],
        inspection_result: [{ required: true, message: "请选择检验结果", trigger: "change" }],
        inspection_content: [{ max: 256, message: "超出最大长度 (256)", trigger: "change" }],
        remark: [{ max: 256, message: "超出最大长度 (256)", trigger: "change" }],
      },
      productItems: [],
      tableColumns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "产品名称",
          dataIndex: "product_name",
        },
        {
          title: "产品编号",
          dataIndex: "product_number",
        },
        {
          title: "产品条码",
          dataIndex: "product_barcode",
        },
        {
          title: "产品规格",
          dataIndex: "product_spec",
        },
        {
          title: "来料数量",
          dataIndex: "total_quantity",
        },
        {
          title: "抽检数量",
          dataIndex: "inspection_quantity",
          scopedSlots: { customRender: "inspection_quantity" },
        },
        {
          title: "合格数量",
          dataIndex: "qualified_quantity",
          scopedSlots: { customRender: "qualified_quantity" },
        },
        {
          title: "不合格数量",
          dataIndex: "unqualified_quantity",
          scopedSlots: { customRender: "unqualified_quantity" },
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],

      inspectionStandardsHearder: [
        {
          title: "AQL",
          dataIndex: "aql",
        },
        {
          title: "CR:0.00",
          dataIndex: "cr",
          scopedSlots: { customRender: "cr" },
        },
        {
          title: "MAJ:1.5",
          dataIndex: "maj",
          scopedSlots: { customRender: "maj" },
        },
        {
          title: "MIN:2.5",
          dataIndex: "min",
          scopedSlots: { customRender: "min" },
        },
      ],

      userItems: [],
      createLoading: false,
      productSelectModalVisible: false,
    };
  },
  computed: {
    operator() {
      return this.$store.state.user.id;
    },
  },
  methods: {
    initData() {
      userOption({ page_size: 999999, is_active: true }).then((data) => {
        this.userItems = data.results;
      });

      inspectionReportNumber().then((data) => {
        this.dataForm = { ...this.dataForm, number: data.number };
      });

      this.dataForm.inspection_date = moment().format("YYYY-MM-DD");
      this.dataForm.inspector = this.operator;
    },
    openProductSelectModal() {
      if (!this.dataForm.stock_in_order) {
        this.$message.warning("请选择关联单据");
        return;
      }
      this.productSelectModalVisible = true;
    },
    onSelectProduct(item) {
      // this.productItems.push({
      //   stock_in_goods: item.id,
      //   product_name: item.goods_item.name,
      //   product_number: item.goods_item.number,
      //   product_barcode: item.goods_item.barcode,
      //   product_spec: item.goods_item.spec,
      //   total_quantity: item.stock_in_quantity,
      //   inspection_quantity: 0,
      //   qualified_quantity: 0,
      //   unqualified_quantity: 0,
      // });

      this.productItems = [
        {
          stock_in_goods: item.id,
          product_name: item.goods_item.name,
          product_number: item.goods_item.number,
          product_barcode: item.goods_item.barcode,
          product_spec: item.goods_item.spec,
          total_quantity: item.stock_in_quantity,
          inspection_quantity: 0,
          qualified_quantity: 0,
          unqualified_quantity: 0,
        },
      ];
    },
    removeProduct(item) {
      this.productItems = this.$functions.removeItem(this.productItems, item);
    },
    create() {
      if (this.productItems.length === 0) {
        this.$message.warning("请选择质检产品");
        return;
      }

      this.$refs.form.validate((valid) => {
        if (valid) {
          const dataForm = { ...this.dataForm, inspection_product_items: [] };
          for (const item of this.productItems) {
            dataForm.inspection_product_items.push({
              stock_in_goods: item.stock_in_goods,
              inspection_quantity: item.inspection_quantity,
              qualified_quantity: item.qualified_quantity,
              unqualified_quantity: item.unqualified_quantity,
            });
          }

          this.createLoading = true;
          inspectionReportCreate(dataForm)
            .then(() => {
              this.$message.success("创建成功");
              this.$router.push({ path: "/inspection/purchase_inspection_report_list" });
            })
            .finally(() => {
              this.createLoading = false;
            });
        }
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
